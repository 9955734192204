import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {
  AddressTarget,
  CashierMandatoryField,
  CashierMandatoryFieldsOverrides,
  FieldPropertiesByTarget,
  FormFieldTarget,
  FormOverrides,
} from '../../../../types/app.types';
import {
  getExcludeGoogleAutoCompleteOverrides,
  getHideCountryOverride,
  getSettingsOverrides,
  mergeOverrides,
  getSettingsOverridesForCustomizableFields,
  getLabelsOverrides,
  mandatoryOverride,
} from '../form.utils';
import {FormOverride} from '@wix/form-viewer/dist/types/services/form-overrides';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {getContactFormInitialState} from '../ContactForm/contactForm.utils';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {DEFAULT_COUNTRY} from '../../constants';
import {getAddressFormInitialState} from '../AddressForm/addressForm.utils';
import {getVatFormInitialState} from '../VatIdForm/VatForm.utils';
import {AddressModel} from '../../../../domain/models/Address.model';
import {CountryCode, FormValues} from '@wix/form-fields';
import {ValueType} from '@wix/form-fields/dist/types/ui/form/types';
import {isCountryWithVat} from '../../../../domain/utils/isCountryWithVat';

const cashierFieldToAddressTarget = {
  [CashierMandatoryField.address]: AddressTarget.address_line,
  [CashierMandatoryField.zipCode]: AddressTarget.postal_code,
  [CashierMandatoryField.countryCode]: AddressTarget.country,
  [CashierMandatoryField.houseNumber]: AddressTarget.street_number,
  [CashierMandatoryField.street]: AddressTarget.street_name,
  [CashierMandatoryField.state]: AddressTarget.subdivision,
  [CashierMandatoryField.city]: AddressTarget.city,
};

function mapCashierMandatoryFieldToOverride(
  cashierMandatoryField: CashierMandatoryField,
  mandatory?: boolean
): FormOverrides | undefined {
  /* istanbul ignore next */
  if (!mandatory) {
    return {};
  }
  switch (cashierMandatoryField) {
    case CashierMandatoryField.address:
    case CashierMandatoryField.city:
    case CashierMandatoryField.countryCode:
    case CashierMandatoryField.houseNumber:
    case CashierMandatoryField.state:
    case CashierMandatoryField.street:
    case CashierMandatoryField.zipCode: {
      const target = cashierFieldToAddressTarget[cashierMandatoryField];
      return {
        address: {
          [target]: mandatoryOverride,
        },
      };
    }
    case CashierMandatoryField.phone:
      return {
        phone: mandatoryOverride,
      };
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */
      return undefined;
  }
}

export function getCashierOverrides(cashierOverrides: CashierMandatoryFieldsOverrides): FormOverrides {
  const fieldTargets = Object.keys(cashierOverrides) as (keyof typeof cashierOverrides)[];

  return fieldTargets.reduce((accOverrides, fieldTarget) => {
    const fieldOverride = mapCashierMandatoryFieldToOverride(fieldTarget, cashierOverrides[fieldTarget]);
    /* istanbul ignore next */
    if (!fieldOverride) {
      return accOverrides;
    }
    return mergeOverrides(accOverrides, fieldOverride);
  }, {});
}

function mapFormFieldPropsToOverrides(props: FieldPropertiesByTarget[string]): FormOverride {
  if (!props) {
    return {
      hidden: true,
      required: false,
    };
  }
  /* istanbul ignore else */
  if (typeof props?.hidden === 'boolean' && typeof props?.required === 'boolean') {
    return {
      hidden: props.hidden,
      required: props.required,
    };
  }
  /* istanbul ignore next */
  return {};
}

export function getShippingDetailsRelatedFieldsOverrides({
  formFieldsProperties,
}: {
  formFieldsProperties: FieldPropertiesByTarget;
}): FormOverrides {
  const phoneProps = formFieldsProperties[FormFieldTarget.phone];
  const companyProps = formFieldsProperties[FormFieldTarget.company_name];
  const addressProps = formFieldsProperties[FormFieldTarget.address] as FieldPropertiesByTarget | undefined;
  const addressLine2Props = addressProps ? addressProps[AddressTarget.address_line_2] : undefined;

  return {
    phone: mapFormFieldPropsToOverrides(phoneProps),
    company_name: mapFormFieldPropsToOverrides(companyProps),
    address: {
      address_line_2: mapFormFieldPropsToOverrides(addressLine2Props),
    },
  };
}

export function getVatIdOverride(country: CountryCode): FormOverrides {
  return isCountryWithVat(country)
    ? {
        vat_id: {
          hidden: false,
          required: true,
        },
      }
    : {};
}

export type BillingFormRelevantSettings = Pick<
  CheckoutSettingsModel,
  | 'enabledDeliveryCountries'
  | 'isLocalDeliveryByZipCodeEnabled'
  | 'addressLine2'
  | 'companyName'
  | 'phone'
  | 'customField'
>;

export function getBillingFormOverrides({
  cashierMandatoryFields,
  checkoutSettings,
  checkoutComposerEnabled,
  shippingDetailsFieldsProps,
  isGroo,
  excludeGoogleAutoComplete,
  localeKeys,
  country,
}: {
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  checkoutSettings: BillingFormRelevantSettings;
  checkoutComposerEnabled: boolean;
  shippingDetailsFieldsProps: FieldPropertiesByTarget;
  isGroo?: boolean;
  excludeGoogleAutoComplete?: boolean;
  localeKeys?: ILocaleKeys;
  country?: CountryCode | null;
}): FormOverrides {
  const cashierMandatoryFieldsOverrides = getCashierOverrides(cashierMandatoryFields);
  const settingsOverrides = getSettingsOverrides({checkoutSettings});
  const settingsOverridesForCustomizableFields = getSettingsOverridesForCustomizableFields(checkoutSettings);
  const shippingDetailsRelatedFieldsOverrides = getShippingDetailsRelatedFieldsOverrides({
    formFieldsProperties: shippingDetailsFieldsProps,
  });
  const hideCountryOverride = isGroo ? getHideCountryOverride() : {};
  const excludeGoogleAutoCompleteOverrides = getExcludeGoogleAutoCompleteOverrides({excludeGoogleAutoComplete});
  const labelsOverrides = localeKeys ? getLabelsOverrides({localeKeys}) : /* istanbul ignore next */ {};
  const vatIdOverride = country ? getVatIdOverride(country) : {};

  return mergeOverrides(
    settingsOverrides,
    checkoutComposerEnabled ? shippingDetailsRelatedFieldsOverrides : settingsOverridesForCustomizableFields,
    hideCountryOverride,
    excludeGoogleAutoCompleteOverrides,
    labelsOverrides,
    vatIdOverride,
    cashierMandatoryFieldsOverrides
  );
}

export function getBillingFormInitialState({
  checkoutSettings,
  contact,
  country = DEFAULT_COUNTRY,
  overridePhone,
  checkoutComposerEnabled,
  initialAddress,
  billingContact,
}: {
  checkoutSettings: CheckoutSettingsModel;
  contact?: ContactModel;
  initialAddress?: AddressModel;
  country?: string;
  overridePhone?: boolean;
  checkoutComposerEnabled: boolean;
  billingContact?: ContactModel;
}) {
  return {
    ...getContactFormInitialState({
      checkoutSettings,
      contact,
      country,
      overridePhone,
      checkoutComposerEnabled,
    }),
    ...getAddressFormInitialState(checkoutSettings, checkoutComposerEnabled, initialAddress),
    ...getVatFormInitialState(billingContact, true),
  };
}

export function getCountryCode(formValues: FormValues): CountryCode | undefined {
  const address = formValues[FormFieldTarget.address] as {[target: string]: ValueType} | undefined;
  const countryCode = address ? address[AddressTarget.country] : undefined;
  return countryCode ? (countryCode as CountryCode) : undefined;
}
